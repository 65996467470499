






































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Selector from "vue-select";
import "vue-select/dist/vue-select.css";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { When } from "@/store/model/when";

@Component({
  components: { Selector, Calendar, DatePicker },
})
export default class WhenSelector extends Vue {
  static uid = 1;
  id: string;
  startHour = "10";
  startMinute = "0";
  startAMPM = false;
  endHour = "10";
  endMinute = "0";
  endAMPM = true;
  date = new Date();

  constructor() {
    super();
    this.id = `whensel-${(WhenSelector.uid++).toString()}`;
  }

  @Prop() value!: When;
  @Watch("value", { deep: true, immediate: true })
  onValueChanged(newValue: When) {
    const dateValues = newValue.dateValues;

    let start = dateValues.start;
    let end = dateValues.end;

    Vue.set(this, "startHour", start.hours.toString());
    Vue.set(this, "startMinute", start.minutes.toString());
    Vue.set(this, "startAMPM", start.ampm);
    Vue.set(this, "endHour", end.hours.toString());
    Vue.set(this, "endMinute", end.minutes.toString());
    Vue.set(this, "endAMPM", end.ampm);
    Vue.set(this, "date", dateValues.date);
  }

  get when() {
    return When.fromDateValues(
      this.date,
      {
        hours: this.startHour,
        minutes: this.startMinute,
        ampm: this.startAMPM,
      },
      {
        hours: this.endHour,
        minutes: this.endMinute,
        ampm: this.endAMPM,
      }
    );
  }
  get duration() {
    return this.when.duration;
  }

  @Watch("when", { deep: true, immediate: true })
  onDateInfo(newValue: When) {
    if (!When.equals(this.value, newValue)) {
      this.$emit("input", newValue);
    }
  }
}
