
















import { Component, Vue } from "vue-property-decorator";
import EventsCard from "@/components/EventsCard.vue";
import RecordCard from "@/views/RecordCard.vue";
import Header from "@/components/Header.vue";

@Component({
  components: {
    EventsCard,
    RecordCard,
    Header,
  },
})
export default class ScheduleView extends Vue {}
