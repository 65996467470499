





































import { Component, ModelSync, Vue, Watch } from "vue-property-decorator";
import Selector from "vue-select";
import "vue-select/dist/vue-select.css";
import { CompanyRecord, findInItemSet } from "@/store/model";
import { CompaniesModule } from "@/store/companies";
import FormButtonsPanel from "@/components/FormButtonsPanel.vue";

@Component({
  components: { Selector, FormButtonsPanel },
})
export default class CompanyEditor extends Vue {
  @ModelSync("value", "input", {
    type: Object,
    required: true,
  })
  currentValue!: CompanyRecord & { validate: () => string[] };

  get errors() {
    return this.currentValue.validate();
  }

  onClose() {
    this.$emit("close");
  }

  onSave() {
    // eslint-disable-next-line prettier/prettier
    (this.currentValue as unknown as { commit: () => void }).commit();
    this.$emit("commit", this.currentValue.id);
    // this.editing = false;
  }
}
