























import { Company } from "@/store/companies";
import { Position } from "@/store/positions";
import { Component, Prop, Vue } from "vue-property-decorator";
import PathLink from "../vue-tt/PathLink.vue";

@Component({
  components: { PathLink },
})
export default class PositionsCard extends Vue {
  @Prop() value!: Company;

  get positions() {
    const ret: Position[] = [];
    if (this.value) {
      const groups: { [name: string]: Position[] } = {
        interview: [],
        applied: [],
        withdraw: [],
        rejected: [],
        none: [],
      };

      const pset = this.value.positions;
      for (const key in pset) {
        if (Object.prototype.hasOwnProperty.call(pset, key)) {
          const position = pset[key];
          const group = groups[position.status];
          if (group) {
            group.push(position);
          }
        }
      }

      ret.push(
        ...groups.interview,
        ...groups.applied,
        ...groups.withdraw,
        ...groups.rejected,
        ...groups.none
      );
    }
    return ret;
  }
}
