

























import { Component, Vue } from "vue-property-decorator";
import PathLink from "../vue-tt/PathLink.vue";
import CompaniesCard from "@/components/company/CompaniesCard.vue";
import RecordCard from "@/views/RecordCard.vue";
import CompanyListCard from "@/components/company/CompanyListCard.vue";
import Header from "@/components/Header.vue";

@Component({
  components: { PathLink, CompaniesCard, RecordCard, CompanyListCard, Header },
})
export default class Companies extends Vue {}
