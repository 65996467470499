











































































import { Component, ModelSync, Vue } from "vue-property-decorator";
import Selector from "vue-select";
import "vue-select/dist/vue-select.css";
import { ContactRecord } from "@/store/model";
import PathLink from "@/vue-tt/PathLink.vue";
import FormButtonsPanel from "@/components/FormButtonsPanel.vue";
import { Contact } from "@/store/contacts";
import { CompaniesModule } from "@/store/companies";

@Component({
  components: { Selector, PathLink, FormButtonsPanel },
})
export default class ContactEditor extends Vue {
  @ModelSync("value", "input", {
    type: Object,
    required: true,
  })
  currentValue!: ContactRecord & { validate: () => string[] };

  get companyName() {
    if (this.currentValue?.companyId) {
      return CompaniesModule.items[this.currentValue?.companyId]?.name ?? "";
    }
    return "";
  }

  get phone() {
    return (this.currentValue.phone ?? []).join("; ");
  }
  set phone(value: string) {
    this.currentValue.phone = (value ?? "").split(/[;,]/).map((i) => i.trim());
  }
  get email() {
    return (this.currentValue.email ?? []).join("; ");
  }
  set email(value: string) {
    this.currentValue.email = (value ?? "").split(/[;,]/).map((i) => i.trim());
  }

  get errors() {
    return this.currentValue.validate();
  }
  onClose() {
    this.$emit("close");
  }

  onSave() {
    // eslint-disable-next-line prettier/prettier
    (this.currentValue as unknown as { commit: () => void }).commit();
    this.$emit("commit", this.currentValue.id);
    // this.editing = false;
  }
}
