












































import { Component, Prop, Vue } from "vue-property-decorator";
import PathLink from "../vue-tt/PathLink.vue";
import AuthButton from "@/components/AuthButton.vue";
import ActionSelector from "@/components/ActionSelector.vue";

@Component({
  components: { PathLink, AuthButton, ActionSelector },
})
export default class Header extends Vue {
  @Prop({ required: false }) title?:
    | string
    | string[]
    | {
        title: string;
        path?: string;
      }
    | {
        title: string;
        path?: string;
      }[];

  @Prop({ required: false }) commands!: {
    title: string;
    click: () => void;
    enabled: boolean;
  }[];

  mode = "none";

  get commandButtons() {
    return this.commands ?? [];
  }

  get titleLinks() {
    const links = (
      this.title == undefined
        ? []
        : Array.isArray(this.title)
        ? this.title
        : [this.title]
    ).map((i) => {
      if (typeof i === "string") {
        return {
          title: i,
          path: undefined,
        };
      }
      return {
        title: i.title,
        path: i.path ?? undefined,
      };
    });
    return links;
  }

  get titleLink() {
    if (this.titleLinks.length > 0) {
      return this.titleLinks[0];
    }
    return {
      title: "JobHunter",
      path: "/",
    };
  }
  get subTitleLinks() {
    if (this.titleLinks.length > 1) {
      return this.titleLinks.slice(1);
    }
    return [];
  }
}
