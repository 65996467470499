






















































import { AuthModule, client_id } from "@/store/auth";
import { Component, Vue } from "vue-property-decorator";
import GoogleLogin from "vue-google-login";
import VueContext from "vue-context";
import "vue-context/dist/css/vue-context.css";
import fileDownload from "js-file-download";
import { exportToSessionInfo } from "@/store/client";

@Component({
  components: { GoogleLogin, VueContext },
})
export default class AuthButton extends Vue {
  get isLoggedIn() {
    return !!AuthModule.credentials;
  }
  get userImage() {
    return AuthModule.imageUrl ?? "/img/user.png";
  }
  get userName() {
    return AuthModule.displayName ?? "Not logged-in";
  }
  get loginParams() {
    return {
      client_id: client_id,
    };
  }

  exportData() {
    const data = {};
    exportToSessionInfo(data);
    // data.contacts = ContactsModule.items;
    // data.positions = PositionsModule.items;
    // data.events = EventsModule.items;
    const dataJ = JSON.stringify({ session: data }, null, 2);
    fileDownload(dataJ, "input.json");
  }
}
