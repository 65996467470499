





















































import { Component, Prop, Vue } from "vue-property-decorator";
import PathLink from "../../vue-tt/PathLink.vue";
import ContactEditor from "@/components/contact/ContactEditor.vue";
import FormButtonsPanel from "@/components/FormButtonsPanel.vue";
import { Contact } from "@/store/contacts";

@Component({
  components: { PathLink, ContactEditor, FormButtonsPanel },
})
export default class CompanyCard extends Vue {
  @Prop() value!: Contact;
  @Prop({ required: false }) enableEdit?: boolean;
  @Prop({ required: false }) enableLink?: boolean;
  @Prop({ required: false, default: true }) enableCompanyLink?: boolean;

  onEdit(e: Event) {
    this.$emit("edit", e);
  }

  get tilePath() {
    return this.enableLink ? this.value.path : undefined;
  }
  get subtitle() {
    if (this.value) {
      const parts: string[] = [];
      if (this.value.title) {
        parts.push(this.value.title);
      }
      const company = this.value.company;
      if (company) {
        parts.push(company.name);
      }

      if (parts.length > 0) {
        return parts.join(" - ");
      }
    }
    return "";
  }

  get linkedIn() {
    return this.value?.linkedIn;
  }

  get phones() {
    if (!Array.isArray(this.value?.phone)) {
      this.value.phone = [this.value.phone as string];
    }
    return (this.value?.phone ?? []).map((title) => {
      const path = "tel:" + title.replace(/\(\)-\s/g, "");

      return {
        path,
        title,
      };
    });
  }

  get emails() {
    if (!Array.isArray(this.value?.email)) {
      this.value.email = [this.value.email as string];
    }
    return (this.value?.email ?? []).map((title) => {
      const path = "mailto:" + title;

      return {
        path,
        title,
      };
    });
  }
}
