


































import { Component, Prop, Vue } from "vue-property-decorator";
import PathLink from "../../vue-tt/PathLink.vue";
import ContactEditor from "@/components/contact/ContactEditor.vue";
import FormButtonsPanel from "@/components/FormButtonsPanel.vue";
import { Company } from "@/store/companies";

@Component({
  components: { PathLink, ContactEditor, FormButtonsPanel },
})
export default class CompanyCard extends Vue {
  @Prop() value!: Company;
  @Prop({ required: false }) enableEdit?: boolean;
  @Prop({ required: false }) enableLink?: boolean;
  @Prop({ required: false, default: true }) enableCompanyLink?: boolean;

  onEdit(e: Event) {
    this.$emit("edit", e);
  }

  get tilePath() {
    return this.enableLink ? this.value.path : undefined;
  }

  get title() {
    return (this.value.active ? "Active: " : "Inactive: ") + this.value.status;
  }
}
