




























import { Component, Prop, Vue } from "vue-property-decorator";
import { Event } from "@/store/events";

@Component({
  components: {},
})
export default class WhenComponent extends Vue {
  @Prop() when?: Event;

  @Prop({ required: false, default: true }) showDate?: boolean;

  get shouldDisplayDate() {
    if (this.showDate === undefined) {
      this.showDate = true;
    }

    return this.when?.startDate?.ready && this.showDate;
  }

  get displayRemaining() {
    if (this.when) {
      if (this.when.isNow) {
        return "now";
      }
      if (this.when?.isInPast) {
        return "completed";
      }
      return this.when?.startDate.displayRemaining;
    }
    return "";
  }
}
