


















import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class FormButtonsPanel extends Vue {
  @Prop() errors!: string[];

  onClose() {
    this.$emit("cancel");
  }
  onSave() {
    this.$emit("submit");
  }
}
