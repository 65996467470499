











import { CompaniesModule } from "@/store/companies";
import { itemSetToArray } from "@/store/model";
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";

@Component({
  components: { Header },
})
export default class Home extends Vue {
  get companies() {
    return itemSetToArray(CompaniesModule.items, (a, b) =>
      a.name < b.name ? -1 : 1
    );
  }
}
