




































































import { Component, Vue } from "vue-property-decorator";
import PathLink from "../vue-tt/PathLink.vue";
import When from "@/components/When.vue";
import Where from "@/components/Where.vue";
import Selector from "vue-select";
import ActionSelector from "@/components/ActionSelector.vue";
import CompanySelector from "@/components/company/CompanySelector.vue";
import ContactSelector from "@/components/contact/ContactSelector.vue";
import ContactsSelector from "@/components/contact/ContactsSelector.vue";
import WhenSelector from "@/components/WhenSelector.vue";
import AddCompanyActivity from "@/components/company/AddCompanyActivity.vue";

import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import "vue-select/dist/vue-select.css";
import { Duration } from "@/store/model/duration";
import { DateInfo } from "@/store/model/date-info";

import WhenData from "@/store/model/when";
import { ContactsModule } from "@/store/contacts";
import { CompaniesModule } from "@/store/companies";

import Header from "@/components/Header.vue";

@Component({
  components: {
    PathLink,
    When,
    Where,
    Selector,
    ActionSelector,
    CompanySelector,
    ContactSelector,
    ContactsSelector,
    Calendar,
    DatePicker,
    WhenSelector,
    AddCompanyActivity,
    Header,
  },
  data: () => {
    return {
      companyVal: {
        type: Object,
        default: undefined,
      },
    };
  },
})
export default class Record extends Vue {
  mode = "none";
  companyId = "";
  contactId = "";

  contacts: string[] = [];

  when: WhenData = new WhenData(new Date(), 60);

  datePickerModel: DatePickerModel = new DatePickerModel();

  get company() {
    const c = CompaniesModule.items[this.companyId];
    return c;
  }

  get contact() {
    return ContactsModule.items[this.contactId];
  }
}

class DatePickerModel {
  dateInfo: DateInfo;
  duration: Duration;

  constructor(date: Date = new Date()) {
    this.dateInfo = new DateInfo(date);
    this.duration = new Duration(30);
  }

  get date() {
    return this.dateInfo.date ?? new Date();
  }
  set date(value: Date) {
    console.info("set date: " + value.toString());
    // retain time
    this.dateInfo.updateDate(value);
  }

  get start() {
    return this.date;
  }
  set start(value: Date) {
    console.info("set start: " + value.toString());
    this.dateInfo.date = value;
  }

  get end() {
    return this.dateInfo.addDuration(this.duration).date ?? new Date();
  }
  set end(value: Date) {
    console.info("set end: " + value.toString());
    this.duration = Duration.fromDateRange(this.start, value);
  }
}
