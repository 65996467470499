



































































































import { Component, Vue } from "vue-property-decorator";
import PathLink from "../vue-tt/PathLink.vue";
import When from "@/components/When.vue";
import Where from "@/components/Where.vue";

import { createEvents, EventAttributes } from "ics";
import fileDownload from "js-file-download";
import { EventsModule, Event } from "@/store/events";
import { filterItemSet, groupItemSet, itemSetToArray } from "@/store/model";

@Component({
  components: { PathLink, When, Where },
})
export default class Companies extends Vue {
  get events() {
    const events = itemSetToArray(
      filterItemSet(EventsModule.items, (item) => !item.isInPast),
      Event.compareStart
    );
    return events;
  }
  get groups() {
    const groups = groupItemSet(
      this.events,
      (item) => item.startDate.displayDate,
      (item, groupId) => {
        return {
          groupId,
          title: item.startDate.displayDate,
          items: [],
        };
      }
    );

    console.info(groups);

    return groups;
  }

  getICal() {
    const icsEvents: EventAttributes[] = [];

    // for (const event of this.events) {
    //   icsEvents.push(createICalEvent(event));
    // }

    createEvents(icsEvents, (err, val) => {
      if (err) {
        console.error(err);
      } else {
        fileDownload(
          val.replaceAll("BEGIN:VEVENT", "BEGIN:VEVENT\nCLASS:PRIVATE"),
          "hunter.events.ics",
          "text/calendar"
        );
      }
    });
  }
}
