


















import { CompaniesModule } from "@/store/companies";
import { Component, Vue } from "vue-property-decorator";
import PathLink from "../../vue-tt/PathLink.vue";
import CompanyTile from "@/components/company/CompanyTile.vue";
import { itemSetToArray } from "@/store/model";

@Component({
  components: { PathLink, CompanyTile },
})
export default class CompaniesCard extends Vue {
  get companies() {
    return itemSetToArray(CompaniesModule.items, (a, b) =>
      a.name < b.name ? -1 : 1
    );
  }

  addNew() {
    this.$router.push("/newcompany");
  }
}
