























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Where } from "@/store/model";
import PathLink from "@/vue-tt/PathLink.vue";

@Component({
  components: { PathLink },
})
export default class WhereComponent extends Vue {
  @Prop() item!: Where;
}
