



























import { Company } from "@/store/companies";
import { Contact, ContactsModule } from "@/store/contacts";
import { Component, Prop, Vue } from "vue-property-decorator";
import PathLink from "../../vue-tt/PathLink.vue";
import ContactTile from "@/components/contact/ContactTile.vue";
import SmartEntryTile from "@/components/SmartEntryTile.vue";
import store from "@/store";

@Component({
  components: { PathLink, ContactTile, SmartEntryTile },
})
export default class ContactsCard extends Vue {
  @Prop() value!: Company;
  @Prop({ required: false }) enableTitleLink?: boolean;

  get contacts() {
    const ret: Contact[] = [];
    if (this.value) {
      const pset = this.value.contacts;
      for (const key in pset) {
        if (Object.prototype.hasOwnProperty.call(pset, key)) {
          const contact = pset[key];
          ret.push(contact);
        }
      }
    }
    return ret.sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
  }

  addNew() {
    // if (this.value) {
    //   this.$router.push(this.value.newContactsPath);
    // }
    this.entryVisible = true;
  }

  entryVisible = false;
  entryClose() {
    this.entryVisible = false;
  }
  entrySubmit(value: string) {
    store.dispatch("contacts/createNew", { companyId: this.value.id, value });
    this.entryVisible = false;
  }
}
