






























































import { Component, Prop, Vue } from "vue-property-decorator";
import PathLink from "../../vue-tt/PathLink.vue";
import When from "@/components/When.vue";
import Where from "@/components/Where.vue";
import { itemSetToArray } from "@/store/model";
import { CompaniesModule } from "@/store/companies";

@Component({
  components: { PathLink, When, Where },
})
export default class CompanyListCard extends Vue {
  @Prop({ required: false, default: "Companies" }) title!: string;
  @Prop({ required: false }) titleLinkPath?: string;

  get companies() {
    return itemSetToArray(CompaniesModule.items, (a, b) =>
      a.name < b.name ? -1 : 1
    );
  }

  get activeCompanies() {
    return this.companies.filter((c) => c.active);
  }
  get inactiveCompanies() {
    return this.companies.filter((c) => !c.active);
  }
}
